import React, { useCallback, useState } from "react"
import { Link } from "gatsby"
import { OutboundLink } from "gatsby-plugin-gtag"
import { trackEvent } from "../lib/track-event"
import Hero from "../components/hero"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Icon from "../components/icon"
import Modal from "../components/modal"

const IndexPage = () => {
  const [showOfficeDepotModal, setShowOfficeDepotModal] = useState(false)
  const toggleOfficeDepotModal = useCallback(() => {
    setShowOfficeDepotModal(!showOfficeDepotModal)
    trackEvent({ label: "office_depot_register", category: "call_to_action" })
  }, [showOfficeDepotModal])
  const [showStaplesModal, setShowStaplesModal] = useState(false)
  const toggleStaplesModal = useCallback(() => {
    setShowStaplesModal(!showStaplesModal)
    trackEvent({ label: "staples_register", category: "call_to_action" })
  }, [showStaplesModal])
  const [showBoxedModal, setShowBoxedModal] = useState(false)
  const toggleBoxedModal = useCallback(() => {
    setShowBoxedModal(!showBoxedModal)
    trackEvent({ label: "boxed_register", category: "call_to_action" })
  }, [showBoxedModal])

  return (
    <Layout hideSources={true} showHero={true} langKey="es">
      <SEO title="NJ PPE Access Program" />

      <Hero className="full-width bg-hero bg-cover mb-12">
        <div className="container lg:max-w-4xl mx-auto p-2 pb-10">
          <p className="text-3xl sm:text-4xl max-w-3xl font-bold mb-5">
            Ya Terminada: Reciba 65% de descuento en productos PPE de
            comerciantes de confianza.
          </p>
          <p className="font-bold text-xl max-w-2xl mb-5">
            Hasta el viernes, 28 de mayo, 2021, las pequeñas empresas y organizaciones
            con menos de 100 empleados pudieron solicitar un descuento adicional
            de 65% en productos PPE. Las empresas y organizaciones que ya fueron
            aprobadas para el descuento de 65% en noviembre o diciembre NO
            tendrán que volver a aplicar.
          </p>
          <p className="mt-5 mb-10">
            <a
              className="btn btn-secondary inline-block mr-5 mb-2"
              href="#vendors"
            >
              Consiga a un comerciante
            </a>
          </p>
          <Link className="block text-white" to="#manufacturers">
            ¿Es fabricante o mayorista de PPE con sede en NJ?
          </Link>
          <Link className="block text-white" to="#retailers">
            ¿Es minorista o mayorista establecido en NJ que vende PPE?
          </Link>
        </div>
      </Hero>

      <div className="prose max-w-none mb-8" id="vendor-links">
        <h2 id="vendors">Comerciantes aprobados en línea</h2>
        <p>
          Regístrese con los comerciantes a continuación para obtener acceso a
          los productos PPE con descuento.
        </p>
      </div>

      <div className="grid sm:grid-cols-3 gap-5 mb-12 justify-center">
        <div className="bg-backgroundHighlight flex flex-col items-center text-center border rounded-md text-black overflow-hidden max-w-md">
          <div className="w-full">
            <Icon name="office-depot" />
          </div>
          <div className="p-6">
            <p className="text-primary font-bold mb-4">
              Para todas las empresas con sede en NJ::
            </p>
            <p className="mb-4 mt-8">
              Office Depot ya no acepta solicitudes.
            </p>
          </div>
        </div>
        <div className="bg-backgroundHighlight flex flex-col items-center text-center border rounded-md text-black overflow-hidden max-w-md">
          <div className="w-full">
            <Icon name="boxed" />
          </div>
          <div className="p-6">
            <p className="text-primary font-bold mb-4">
              Para todas las empresas con sede en NJ:
            </p>
            <p className="mb-4 mt-8">
              Boxed ya no acepta solicitudes.
            </p>
          </div>
        </div>
        <div className="bg-backgroundHighlight flex flex-col items-center text-center border rounded-md text-black overflow-hidden max-w-md">
          <div className="w-full">
            <Icon name="staples" />
          </div>
          <div className="p-6">
            <p className="text-primary font-bold mb-4">
              Para todas las empresas con sede en NJ:
            </p>
            <p className="mb-4 mt-8">
              Staples ya no acepta solicitudes.
            </p>
          </div>
        </div>
      </div>

      <div className="alert alert-primary" role="alert">
        <p>
          <strong>Nota sobre la disponibilidad del producto</strong>
        </p>
        <p>
          A pesar de que los comerciantes en nuestro programa están emocionados
          de compartir una gama de PPE, estamos conscientes que hay una escasez
          nacional de muchos artículos de PPE. Por lo tanto, ocasionalmente,
          podría haber una disponibilidad limitada de suministros. Estos son
          conocidos como “stock-outs” entre nuestros proveedores:
          <p>
            Office Depot: actualmente no hay disponibilidad de guantes médicos
          </p>
        </p>
      </div>

      <div className="full-width bg-backgroundHighlight py-8 mb-12">
        <div className="prose text-center max-w-none">
          Se están examinando posibles colaboraciones con más proveedores. Si le
          interesa convertirse en proveedor, por favor{" "}
          <Link to="#retailers">vea la sección de Minoristas</Link>.
        </div>
      </div>

      <div className="mb-12">
        <Link
          to="/ppe_needs"
          className="flex items-center p-5 border rounded-md hover:shadow-lg transition-shadow duration-150 cursor-pointer no-underline text-primary max-w-3xl mx-auto"
        >
          <div className="h-40 w-40 flex-shrink-0">
            <Icon name="planning" />
          </div>
          <p className="mb-0 font-bold text-xl">
            Calcule las necesidades que su empresa tiene de PPE con una
            herramienta interactiva de orientación y planificación de PPE
          </p>
        </Link>
      </div>

      <div className="full-width bg-backgroundHighlight pt-16 px-5 mb-8">
        <div className="prose max-w-4xl mx-auto">
          <h2 id="about">Programa de Acceso a PPE de NJ</h2>

          <p>
            El Programa de Acceso a Equipos de Protección Personal (PPE) de
            Nueva Jersey (PPE Access Program) es una innovadora asociación entre
            el sector público y privado que ayuda a las empresas y
            organizaciones sin fines de lucro a proteger la salud y la seguridad
            de sus empleados y clientes. A través del programa, todas las
            empresas y organizaciones sin fines de lucro con sede en NJ son
            elegibles para al menos un 10% en descuentos de sus compras de PPE
            realizadas a través de comerciantes en línea aprobados.{" "}
            <Link to="#vendor-links">Haga clic aquí para empezar.</Link>
            .​
          </p>

          <p>
            Todas las organizaciones también pueden utilizar una herramienta de
            planificación de PPE en línea que es gratuita y sirve para estimar
            cuánto PPE necesitarán en los próximos meses.{" "}
            <Link to="/ppe_needs">
              Haga clic aquí para estimar sus necesidades de PPE
            </Link>
            .​
          </p>
          <p>
            <strong className="text-primary">¿Quiere aprender más?</strong> Eche
            un vistazo a nuestras{" "}
            <a href="/pdfs/NJEDA_PPE_Program_FAQs-v2.pdf">
              Preguntas Frecuentes.
            </a>
          </p>
        </div>
        <div className="py-8 mx-auto max-w-2xl center">
          <iframe
            src="https://player.vimeo.com/video/480895900"
            width="640"
            height="360"
            style={{ maxWidth: "100%" }}
            frameBorder="0"
            allow="autoplay; fullscreen"
            title="Program Overview Video"
          ></iframe>
        </div>
      </div>

      <div className="prose max-w-none mb-8">
        <h2 id="manufacturers">Para Fabricantes/Mayoristas con sede en NJ</h2>
        <p>
          Como parte de nuestros esfuerzos para apoyar a los fabricantes y
          mayoristas locales, estamos ofreciendo incentivos a los minoristas
          para comprar PPE que se fabrique en Nueva Jersey o proviene de
          pequeños mayoristas en comunidades históricamente desatendidas. Para
          ser incluido en nuestra lista de fabricantes y mayoristas con sede en
          Nueva Jersey, complete el formulario que le corresponde:
        </p>
      </div>

      <div className="grid sm:grid-cols-3 gap-5 mb-12">
        <OutboundLink
          href="https://forms.business.nj.gov/ppe-access/manufacturers/"
          className="flex flex-col items-center text-center p-5 border rounded-md hover:shadow-lg transition-shadow duration-150 cursor-pointer no-underline text-black"
        >
          <div className="h-24 w-24 mb-6">
            <Icon name="vendor" />
          </div>{" "}
          <p className="mb-0 font-bold">Registro para Fabricantes</p>
        </OutboundLink>
        <OutboundLink
          href="https://forms.business.nj.gov/ppe-access/wholesalers/"
          className="flex flex-col items-center text-center p-5 border rounded-md hover:shadow-lg transition-shadow duration-150 cursor-pointer no-underline text-black"
        >
          <div className="h-24 w-24 mb-6">
            <Icon name="vendor" />
          </div>
          <p className="mb-0 font-bold">Registro para Mayoristas</p>
        </OutboundLink>
        <OutboundLink
          href="https://covid19.nj.gov/faqs/nj-information/uncategorized/nj-manufacturers-and-small-wholesalers:-information-on-how-to-access-funding-support-and-register-your-interest-in-selling-products-to-the-designated-vendors"
          className="flex flex-col items-center text-center p-5 border rounded-md hover:shadow-lg transition-shadow duration-150 cursor-pointer no-underline text-black"
        >
          <div className="h-24 w-24 mb-6">
            <Icon name="boxes" />
          </div>
          <p className="mb-0 font-bold break-words">
            Preguntas Frecuentes Fabricantes/Mayoristas
          </p>
        </OutboundLink>
      </div>

      <div className="prose max-w-none">
        <p>
          Si tiene alguna pregunta, envíenos un correo electrónico a{" "}
          <OutboundLink href="mailto:SmallBusinessPPE@njeda.com">
            SmallBusinessPPE@njeda.com
          </OutboundLink>
        </p>
      </div>

      <Modal onExit={toggleStaplesModal} show={showStaplesModal}>
        <h1 className="mb-5 text-staples">Staples 10% PPE Discount</h1>

        <div className="grid grid-cols-3 gap-5 items-stretch justify-between mb-8">
          <div className="max-w-xs flex flex-col items-center">
            <div className="bg-staples rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              1
            </div>
            <p>
              Register on the Staples portal by pressing "Get Started" below
            </p>
          </div>
          <div>
            <div className="bg-staples rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              2
            </div>
            <p>
              Receive registration confirmation via your email approximately 24
              hours. Please keep an eye out for a Staples email in your inbox.
            </p>
          </div>
          <div>
            <div className="bg-staples rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              3
            </div>
            <p>
              Log in, and add PPE items to your shopping cart! The 10% discount
              is already included.
            </p>
          </div>
        </div>
        <OutboundLink
          className="btn bg-staples text-white"
          href="https://go.staples.com/njppeaccess"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Get Started
        </OutboundLink>
      </Modal>

      <Modal onExit={toggleOfficeDepotModal} show={showOfficeDepotModal}>
        <h1 className="mb-5 text-officeDepot">Office Depot 10% PPE Discount</h1>

        <div className="grid grid-cols-3 gap-5 items-stretch justify-between mb-8">
          <div className="max-w-xs flex flex-col items-center">
            <div className="bg-officeDepot rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              1
            </div>
            <p>
              Register on the Office Depot portal below & sign in with your
              credentials.
            </p>
          </div>
          <div>
            <div className="bg-officeDepot rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              2
            </div>
            <p>
              View the “Shopping List” for PPE. All PPE prices include a 10%
              discount.
            </p>
          </div>
          <div>
            <div className="bg-officeDepot rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              3
            </div>
            <p>
              Add items to shopping cart and purchase! The 10% discount is
              already included.
            </p>
          </div>
        </div>
        <OutboundLink
          className="btn bg-officeDepot text-white"
          href="http://www.business.officedepot.com/njppeaccess"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Get Started
        </OutboundLink>
      </Modal>

      <Modal onExit={toggleBoxedModal} show={showBoxedModal}>
        <h1 className="mb-5 text-boxed">Boxed 10% PPE Discount</h1>

        <div className="grid grid-cols-3 gap-5 items-stretch justify-between mb-8">
          <div className="max-w-xs flex flex-col items-center">
            <div className="bg-boxed rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              1
            </div>
            <p>
              Register on the Boxed portal below & sign in with your
              credentials.
            </p>
          </div>
          <div>
            <div className="bg-boxed rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              2
            </div>
            <p>View all PPE items, and add to your shopping cart.</p>
          </div>
          <div>
            <div className="bg-boxed rounded-full text-white font-bold text-xl h-10 w-10 leading-10 inline-block text-center mb-5">
              3
            </div>
            <p>10% discount will appear in your shopping cart upon purchase.</p>
          </div>
        </div>

        <OutboundLink
          className="btn bg-boxed text-white"
          href="https://boxed.com/njppeaccess"
          target="_blank"
          rel="nofollow noopener noreferrer"
        >
          Get Started
        </OutboundLink>
      </Modal>
    </Layout>
  )
}

export default IndexPage
